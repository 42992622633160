<!-- =========================================================================================
  File Name: HoverlayAnalytics.vue
  Description: Hoverlay Analytics
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="hoverlay-analytics">
    <!-- <init-data></init-data> -->
    <!-- {{ placements }} -->
    <!-- ROW 2 -->
    <!-- <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line hideChart class="mb-base" icon="EyeIcon" icon-right statistic="86%" statisticTitle="Views" />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line hideChart class="mb-base" icon="ServerIcon" icon-right statistic="1.2gb" statisticTitle="Active locations" color="success" />
      </div>

       <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line hideChart class="mb-base" icon="ActivityIcon" icon-right statistic="0.1%" statisticTitle="Placed objects" color="danger" />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line hideChart class="mb-base" icon="AlertOctagonIcon" icon-right statistic="13" statisticTitle="Unplaced objects" color="warning" />
      </div>
    </div> -->
    <!-- LINE CHART -->

    <div class="vx-row">
      <!-- <div class="vx-col w-full md:w-full mb-base">
        <vx-card title="Revenue">
          <template slot="actions">
            <feather-icon icon="SettingsIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
          </template>
          <div slot="no-body" class="p-6 pb-0">
            <div class="flex" v-if="revenueComparisonLine.analyticsData">
              <div class="mr-6">
                <p class="mb-1 font-semibold">This Month</p>
                <p class="text-3xl text-success">
                  <sup class="text-base mr-1">$</sup>{{ revenueComparisonLine.analyticsData.thisMonth.toLocaleString() }}
                </p>
              </div>
              <div>
                <p class="mb-1 font-semibold">Last Month</p>
                <p class="text-3xl">
                  <sup class="text-base mr-1">$</sup>{{ revenueComparisonLine.analyticsData.lastMonth.toLocaleString() }}
                </p>
              </div>
            </div>
            <vue-apex-charts type="line" height="300" :options="analyticsData.revenueComparisonLine.chartOptions" :series="revenueComparisonLine.series" />
          </div>
        </vx-card>
      </div> -->

      <!-- <div class="vx-col w-full md:w-1/3 mb-base">
                <statistics-card-line
                  hideChart
                  class="mb-base"
                  icon="EyeIcon"
                  statistic="36.9k"
                  statisticTitle="Views" />
            </div> -->
    </div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3 mb-base">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="EyeIcon"
          icon-right
          :statistic="totalFoundCount"
          :statisticTitle="$t('Views')"
        />
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="CheckIcon"
          icon-right
          :statistic="Object.keys(spaces).length"
          :statisticTitle="$t('ActiveLocation')"
          color="success"
        />
      </div>

      <div v-if="spaces.length > 0" class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3 mb-base">
        <vx-card :title="$t('MostViewed')">
          <div v-for="(space, index) in mostViewedSpaces" :key="space.pid" :class="{ 'mt-4': index }">
            <div class="flex justify-between">
              <div class="flex flex-col">
                <span class="mb-1">{{ space.found_count }} {{ $t('Views') }} </span>
                <h4>{{ space.name | truncate(45) }}</h4>
              </div>
              <div class="flex flex-col text-right">
                <span class="text-grey">{{ (space.found_count / totalFoundCount) | percentage }} </span>
              </div>
            </div>
            <vs-progress :percent="(space.found_count / totalFoundCount) * 100"></vs-progress>
          </div>
        </vx-card>
      </div>

      <div v-if="spaces.length > 0" class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3 mb-base">
        <vx-card :title="$t('LeastViewed')">
          <div v-for="(space, index) in leastViewedSpaces" :key="space.pid" :class="{ 'mt-4': index }">
            <div class="flex justify-between">
              <div class="flex flex-col">
                <span class="mb-1">{{ space.found_count }} {{ $t('Views') }} </span>
                <h4>{{ space.name | truncate(45) }}</h4>
              </div>
              <div class="flex flex-col text-right">
                <span class="text-grey">{{ (space.found_count / totalFoundCount) | percentage }} </span>
              </div>
            </div>
            <vs-progress :percent="(space.found_count / totalFoundCount) * 100"></vs-progress>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import analyticsData from '@/views/ui-elements/card/analyticsData.js'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
import VxTimeline from '@/components/timeline/VxTimeline'

import InitData from '../../components/InitData.vue'

export default {
  data() {
    return {
      layer_pid: JSON.parse(localStorage.getItem('layerInfo')).pid,
      checkpointReward: {},
      subscribersGained: {},
      ordersRecevied: {},
      salesBarSession: {},
      supportTracker: {},
      productsOrder: {},
      salesRadar: {},
      browserStatistics: [],
      clientRetentionBar: {},
      customersData: {},
      revenueComparisonLine: {},
      timelineData: [
        {
          color: 'primary',
          icon: 'PlusIcon',
          title: 'Client Meeting',
          desc: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          time: '25 mins Ago',
        },
        {
          color: 'warning',
          icon: 'MailIcon',
          title: 'Email Newsletter',
          desc: 'Cupcake gummi bears soufflé caramels candy',
          time: '15 Days Ago',
        },
        {
          color: 'danger',
          icon: 'UsersIcon',
          title: 'Plan Webinar',
          desc: 'Candy ice cream cake. Halvah gummi bears',
          time: '20 days ago',
        },
        {
          color: 'success',
          icon: 'LayoutIcon',
          title: 'Launch Website',
          desc: 'Candy ice cream cake. Halvah gummi bears Cupcake gummi bears soufflé caramels candy.',
          time: '25 days ago',
        },
        {
          color: 'primary',
          icon: 'TvIcon',
          title: 'Marketing',
          desc: 'Candy ice cream cake. Halvah gummi bears Cupcake gummi bears.',
          time: '28 days ago',
        },
      ],

      analyticsData,
      dispatchedOrders: [],
    }
  },
  components: {
    VueApexCharts,
    StatisticsCardLine,
    ChangeTimeDurationDropdown,
    VxTimeline,
    InitData,
  },
  computed: {
    mostViewedSpaces() {
      var spaces = Object.assign([], this.spaces)
      console.log(spaces)
      return spaces.sort(this.sortByFoundCount).slice(0, 5)
    },
    leastViewedSpaces() {
      var spaces = Object.assign([], this.spaces)
      return spaces
        .sort(this.sortByFoundCount)
        .reverse()
        .slice(0, 5)
    },
    totalFoundCount() {
      var totalFoundCount = 0
      if (this.spaces)
        for (var key in this.spaces) {
          totalFoundCount += this.spaces[key].found_count
        }
      return totalFoundCount
    },
    maxFoundCount() {
      var maxFoundCount = 0
      if (this.spaces)
        for (var key in this.spaces) {
          if (this.spaces[key].found_count > maxFoundCount) maxFoundCount = this.spaces[key].found_count
        }
      return maxFoundCount
    },
    spaces() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.spaces
      } else return []
    },
    anchors() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.anchors
      } else return []
    },
  },
  methods: {
    sortByFoundCount: function(a, b) {
      return b.found_count - a.found_count
    },
    async getUserLayerSpaces() {
      await this.$store.dispatch('hoverlay/getUserLayerSpaces', { pid: this.layer_pid })
    },
  },
  async mounted() {},
  async created() {
    await this.getUserLayerSpaces()
  },
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#hoverlay-analytics {
  .greet-user {
    position: relative;

    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */
</style>
